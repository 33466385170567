/* Footer */
.footer {
    background-color: #191A19;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px 100px 10px 100px;

}

.footer-logo {
    width: 30px;
}

.footer p {
    font-size: 14px;
    color: gray;
    margin: 0;
    cursor: pointer;
}

.footer a {
    color: gray;
    text-decoration: none;
    font-weight: 500;
}

.footer-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    flex-wrap: wrap;
}

.footer-sections div {
    width: 200px;
}

.footer-sections p {
    font-size: 14px;
    color: gainsboro;
    padding: 5px;
}

.footer-sections h5 {
    color: white;
}

.icon-container a {
    margin: 10px;
}

@media (max-width:1300px) {
    #footer h6 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 5px;
    }

    .footer-sections p {
        font-size: 18px;
        color: gainsboro;
        padding: 5px;
    }

    .footer .copy-right {
        font-size: 18px;
        color: gray;
        margin: 0;
        cursor: pointer;
    }

    .icon-container a {
        margin: 10px;
        font-size: 25px;
    }

    .footer-sections div {
        width: 200px;
    }

    .footer {
        padding: 10px 20px 10px 20px;
    }

}

@media (max-width:900px) {
    .footer {
        padding: 30px 100px 10px 100px;
    }

    .footer-sections div {
        width: 250px;
    }

    #footer h6 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 5px;
    }

    .footer-sections p {
        font-size: 20px;
        color: gainsboro;
        padding: 5px;
    }

    .footer .copy-right {
        font-size: 15px;
        color: gray;
        margin: 0;
        cursor: pointer;
    }

    .icon-container a {
        margin: 10px;
        font-size: 20px;
    }

    .footer-logo {
        width: 20px;
    }

}

@media (max-width:550px) {
    .footer {
        padding: 30px 10px 10px 10px;
    }

    .footer-sections div {
        width: 100px;
    }

    #footer h6 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 5px;
    }

    .footer-sections p {
        font-size: 12px;
        color: gainsboro;
        padding: 5px;
    }

    .footer .copy-right {
        font-size: 10px;
        color: gray;
        margin: 0;
        cursor: pointer;
    }

    .icon-container a {
        margin: 10px;
        font-size: 13px;
    }

    .footer-logo {
        width: 20px;
    }
}

@media (max-width:500px) {
    .footer {
        padding: 30px 10px 10px 10px;
    }

    .footer-sections div {
        width: 150px;
    }

    #footer h6 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 5px;
    }

    .footer-sections p {
        font-size: 12px;
        color: gainsboro;
        padding: 5px;
    }

    .footer .copy-right {
        font-size: 10px;
        color: gray;
        margin: 0;
        cursor: pointer;
    }

    .icon-container a {
        margin: 10px;
        font-size: 13px;
    }

    .footer-logo {
        width: 20px;
    }
}