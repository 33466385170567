.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 0px 20px;
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 25px rgb(49, 49, 49);
}

.logo-img {
  max-width: 200px;
  max-height: 50px;
  padding: 10px;
}

.menus {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buy-menu {
  color: rgb(255, 196, 0);
}

.menus .menu {
  text-decoration: none;
  padding: 0px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.5s;
}

.menus .menu:hover {
  color: rgb(255, 196, 0);
}

// hamburger menu

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  // border: 1px solid blue;
}

.hamburger-menu span {
  width: 30px;
  height: 3px;
  margin: 2px;
  background: linear-gradient(40deg, rgb(68, 0, 255), rgb(255, 38, 96));
  animation-name: gradient;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.side-menu {
  display: none;
}

.right-arrow {
  width: 13px;
}

.down-arrow {
  width: 16px;
}

@keyframes gradient {
  0% {
    background: linear-gradient(40deg, blue, rgb(255, 38, 114));
  }

  25% {
    background: linear-gradient(40deg, rgb(0, 98, 255), rgb(255, 73, 137));
  }

  50% {
    background: linear-gradient(40deg, rgb(0, 170, 255), rgb(255, 80, 217));

  }

  100% {
    background: linear-gradient(40deg, blue, rgb(255, 38, 114));
  }
}

.hamburger-close-menu {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.hamburger-close-menu span {
  width: 30px;
  height: 3px;
  background: linear-gradient(40deg, rgb(68, 0, 255), rgb(255, 38, 96));
}

.hamburger-close-menu span:nth-child(1) {
  margin-left: 3px;
  transform: rotate(50deg);
}

.hamburger-close-menu span:nth-child(2) {
  transform: translate(-10px, -200px);
  transform: rotate(130deg);
}

.dropbtn {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 18px;
  font-weight: 400;
  border: none;
}

.dropbtn:hover {
  color: rgb(255, 196, 0);
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 73px;
  left: -35px;
  background-color: black;
  min-width: 240px;
  box-shadow: 0px 0px 30px 0px rgba(255, 190, 68, 0.291);
  z-index: 100;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
  transform-origin: center top;
}

/* Links inside the dropdown */
.dropdown-content a {
  position: relative;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: all 0.5s ease;
  color: white;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  color: rgb(255, 196, 0);
  border-left: 5px solid rgb(255, 196, 0);
}

/* • */
.bullet {
  display: none;
}

.dropdown-content a:hover .bullet {
  display: inline;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: rgb(255, 196, 0);
}

.wrapper {
  display: none;
}

/* hover effect */
.spot {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.svg-wrapper {
  margin-top: 0;
  position: relative;
  width: 150px;
  /*make sure to use same height/width as in the html*/
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px
}

#shape {
  stroke-width: 6px;
  fill: transparent;
  stroke-dasharray: 85 400;
  stroke-dashoffset: -220;
  transition: 1s all ease;
}

#text {
  margin-top: -35px;
  text-align: center;
}

#text a {
  color: white;
  text-decoration: none;
  font-weight: 100;
  font-size: 1.1em;
}

.svg-wrapper:hover #shape {
  stroke-dasharray: 50 0;
  stroke-width: 3px;
  stroke-dashoffset: 0;
  stroke: rgb(255, 196, 0);
}

/* hover effect */
.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

$colors: (
  raise: rgb(255, 196, 0),
);

@each $button,
$color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: rgb(255, 196, 0);
  }
}

button {
  color: var(--color);
  transition: 0.25s;

  &:hover,
  &:focus {
    border-color: var(--hover);
    color: #fff;
  }
}

// Basic button styles
button {
  background: none;
  border: none;
  font: inherit;
  line-height: 1;
  margin: 0px;
  padding: 1.1em 2em;
  font-weight: 500;
  color: white;
}