/* whats app floating icon */
* {
    font-family: 'Open Sans', sans-serif;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.whats-app-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 30px;
    background-color: #00ab55;
    color: #FFF;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    box-shadow: 2px 2px 3px rgba(167, 221, 172, 0.196);
    z-index: 100;
    padding: 10px;
    animation: ripple 1.5s linear infinite;
}

.whats-app-btn .whatsapp-btn {
    font-size: 30px;
    color: white;
    margin-bottom: 6px;
}

@media (max-width:1300px) {

    .whats-app-btn .whatsapp-btn {
        font-size: 52px;
        color: white;
        margin-bottom: 6px;
    }

    .whats-app-btn {
        position: fixed;
        width: 85px;
        height: 85px;
        font-size: 40px;
    }
}

@media (max-width:900px) {

    .whats-app-btn .whatsapp-btn {
        font-size: 35px;
        color: white;
        margin-bottom: 6px;
    }

    .whats-app-btn {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 60px;
        right: 30px;
        font-size: 25px;
    }
}