.accordion__button {
    font-weight: 500 !important;
    border-radius: 0px;
    background-color: rgba(0, 0, 0) !important;
    color: white !important;
    border: 2px solid rgb(255, 196, 0) !important;
    margin-top: -1px !important;
}



.accordion__item {
    /* border-color: rebeccapurple; */
    background-color: transparent;
    color: #fff;
    border: none !important;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
    color: white;
    font-weight: 300;
    text-align: left;
    border-radius: 0px;
    background-color: rgba(255, 196, 0, 0.099);
    border: 1px solid rgb(255, 196, 0);
}

.accordion__heading {
    font-weight: 400 !important;
}

@media (max-width:1300px) {
    .accordion__heading {
        font-weight: 400 !important;
        font-size: 24px;
    }

    .accordion__panel {
        padding: 20px;
        animation: fadein 0.35s ease-in;
        color: white;
        font-weight: 300;
        text-align: left;
        border-radius: 0px;
        background-color: rgba(255, 196, 0, 0.099);
        border: 1px solid rgb(255, 196, 0);
        font-size: 22px;
        letter-spacing: 1px;
    }
}

@media (max-width:900px) {
    .accordion__heading {
        font-size: 14px;
    }

    .accordion__panel {
        font-size: 12px;
        letter-spacing: 1px;
    }
}