body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: black;
}

.card-box {
    position: relative;
    margin: 40px;
    width: 250px;
    height: 340px;
    background: linear-gradient(0deg, black, rgb(48, 48, 48));
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}

.card-box h4 {
    background: linear-gradient(40deg, rgb(255, 0, 102), blue);
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
}

.glow::before,
.glow::after {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg,
            rgb(107, 0, 156),
            rgb(56, 56, 255),
            rgb(0, 140, 255),
            rgb(0, 166, 192),
            rgb(254, 1, 90),
            rgb(255, 2, 183),
            rgb(255, 143, 225));
    border-radius: 15px;
    background-size: 400%;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    z-index: -1;
    animation: animate 20s linear infinite;
}

@keyframes animate {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.glow::after {
    filter: blur(10px);
    opacity: 0;
    transition: all 0.7s;
}

.card-box:hover.glow::after {
    filter: blur(40px);
    opacity: 0.9;
}

/* card image */
.card-img {
    width: 100%;
    border-radius: 15px;
    height: 50%;
    object-fit: cover;
}

.card-box p {
    font-size: 14px;
    font-weight: 300;
    color: rgb(177, 177, 177);
    padding: 10px;
    letter-spacing: 0.3px;
    margin: 0;
}


.text {
    font-size: 20px;
    background: #121fcf;
    background: linear-gradient(to right, #121fcf 0%, #cf1512 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h6 {
    background: linear-gradient(40deg, rgb(255, 74, 146), rgb(116, 116, 255));
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    transition: all 0.5s;
    color: white;
}

h6 span {
    display: none;
}

h6:hover span {
    display: inline;
}

.learn-more .right-arrow {
    width: 20px;
    z-index: 10;
    margin-left: 5px;
}

@media (max-width:1300px) {
    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 340px;
        margin: 20px;
        position: relative;
        text-align: center;
        width: 280px;
    }

    .card-box h4 {
        margin-bottom: 0;
        margin-top: 0px;
        padding: 5px;
        font-size: 26px;
    }

    .card-box p {
        font-size: 17px;
        font-weight: 300;
        padding: 10px;
    }

    h6 {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 5px;
    }
}

@media (max-width:500px) {
    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 250px;
        margin: 10px;
        position: relative;
        text-align: center;
        width: 150px;
    }

    .card-box h4 {
        margin-bottom: 0;
        margin-top: 15px;
        padding: 5px;
        font-size: 13px;
    }

    .card-box p {
        font-size: 10px;
        font-weight: 300;
        padding: 5px;
    }

    h6 {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0px;
        padding: 5px;
    }
}