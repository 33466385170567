.contact {
    padding-top: 60px;
    background-color: black;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

.contact h1 {
    text-align: center;
    color: white;
}


@media (max-width:1300px) {
    .form-container {
        padding: 25px;
    }

    .contact {
        padding-top: 100px;
        background-color: black;
        color: white;
        font-family: 'Open Sans', sans-serif;
    }
}

@media (max-width:960px) {
    .form-container {
        padding: 25px;
    }

    .contact {
        padding-top: 60px;
        background-color: black;
        color: white;
        font-family: 'Open Sans', sans-serif;
    }
}