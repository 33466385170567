.bg-black {
    background-color: black;
    color: white;
    padding-top: 60px;
}

.form-container,
.details-container {
    padding: 100px;
}

.details {
    padding: 50px;
}

.details h1 {
    font-size: 45px;
    font-weight: 500;
}

.details span {
    font-size: 45px;
    font-weight: 500;
    margin-left: 0px;
    color: rgb(255, 196, 0);
    /* background: linear-gradient(40deg, rgb(255, 0, 76), rgb(255, 196, 0)); */
    /* background: linear-gradient(40deg, rgb(255, 0, 76), rgb(0, 234, 255)); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white; */
}

.faq-section {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}




/* Brand */
.brand {
    background-color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.brand-img {
    max-width: 150px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

.brand-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(39, 39, 39);
    box-shadow: 0px 5px 15px rgb(29, 29, 29);
    margin: 10px;
    max-width: 150px;
}

.brand-box:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(39, 39, 39);
    box-shadow: 5px 5px 15px rgb(125, 125, 125);
    margin: 10px;
}

.marquee {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 200px;
}

@media (max-width:1300px) {
    .bg-black {
        background-color: black;
        color: white;
        padding-top: 100px;
    }

    .form-container,
    .details-container {
        padding: 40px;
    }

    .brand-img {
        max-width: 180px;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
    }

    .brand-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgb(39, 39, 39);
        box-shadow: 0px 5px 15px rgb(29 29 29);
        margin: 10px;
        max-width: 200px;
    }
}

@media (max-width:960px) {

    .bg-black {
        background-color: black;
        color: white;
        padding-top: 60px;
    }

    .form-container {
        padding: 25px;
    }

    .details-container {
        padding: 25px;
    }

    .form {
        padding: 25px;
    }

    .details {
        padding: 0px;
        height: 150px;
    }

    .details h1 {
        font-size: 30px;
    }

    .details span {
        font-size: 30px;
    }

    /* brand */
    .brand-box {
        height: 60px;
    }

    .brand-img {
        max-width: 150px;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
    }

    .brand-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgb(39, 39, 39);
        box-shadow: 0px 5px 15px rgb(29, 29, 29);
        margin: 10px;
        max-width: 150px;
    }

}