body {
    background-color: black;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

.html {
    background-color: black;
    position: relative;
    font-family: 'Open Sans', sans-serif;
}

/* banner */
.banner {
    width: 100%;
    height: 550px;
    background-color: black;
    padding: 100px 50px 50px 50px;
    margin: 0;
}

.banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
}

.banner-content h1 {
    color: rgb(255, 196, 0);
}

.banner-heading {
    height: 60px;
}

/* Our-services */

#section2 {
    padding: 50px 10px 10px 10px;
    position: relative;
}

#section2 .bg-img-section2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -2;
}

#section2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 4, 12, 0.721);
    z-index: -1;
}

.service-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 10;
}

.service-section a {
    text-decoration: none;
}

.heading {
    font-size: 50px;
    font-weight: 500;
    color: white;
    text-align: center;
}


/* Brand */
.brand {
    background-color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.brand-img {
    max-width: 150px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

.brand-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(39, 39, 39);
    box-shadow: 0px 5px 15px rgb(29, 29, 29);
    margin: 10px;
    max-width: 150px;
}

.brand-box:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(39, 39, 39);
    box-shadow: 5px 5px 15px rgb(255, 192, 32);
    margin: 10px;
}

.marquee {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 200px;
}

/* FAQ */
.faq {
    padding: 50px 100px;
    background-color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.videoTag {
    width: 100%;
}



@media (max-width: 1300px) {

    /* banner */
    .banner {
        height: 100%;
        padding: 150px 0px 0px 0px;
    }

    .banner-content {
        padding: 20px;
        height: 100%;
    }

    .banner-heading {
        height: 50px;
    }

    /* Our-services */
    .our-services {
        background-color: black;
        text-align: center;
        padding: 10px 10px;
        height: auto;
    }

    .heading {
        font-size: 25px;
    }

    .container {
        padding: 0px;
    }

    .container .box {
        margin: 10px 10px;
    }

    .banner-content h1 {
        color: rgb(255, 196, 0);
        font-size: 31px;
    }

    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 340px;
        margin: 20px;
        position: relative;
        text-align: center;
        width: 270px;
    }

    /* FAQ */
    .faq {
        padding: 50px 10px;
        background-color: black;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }


}

@media (max-width: 960px) {

    /* banner */
    .banner {
        height: 100%;
    }

    .banner {
        height: 100%;
        padding: 80px 0px 0px 0px;
    }

    .container .box {
        margin: 20px 20px;
    }

    /* brand */
    .brand-box {
        height: 60px;
    }

    .banner-content h1 {
        color: rgb(255, 196, 0);
        font-size: 25px;
    }
}

@media (max-width: 650px) {

    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 260px;
        margin: 10px;
        position: relative;
        text-align: center;
        width: 160px;
    }

    .card-box h4 {
        margin-bottom: 0;
        margin-top: 0px;
        padding: 5px;
        font-size: 16px;
    }

    .card-box p {
        font-size: 10px;
        font-weight: 300;
        padding: 10px;
    }

    h6 {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0px;
        padding: 5px;
    }

}

@media (max-width: 400px) {

    .banner-content h1 {
        font-size: 19px;
    }

    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 260px;
        margin: 10px;
        position: relative;
        text-align: center;
        width: 157px;
    }

}

@media (max-width: 360px) {

    .banner-content h1 {
        font-size: 16px;
    }

    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 250px;
        margin: 10px;
        position: relative;
        text-align: center;
        width: 130px;
    }

    .card-box h4 {
        margin-bottom: 0;
        margin-top: 0px;
        padding: 5px;
        font-size: 13px;
    }

}

@media (max-width: 280px) {

    .banner-content h1 {
        font-size: 16px;
    }

    .card-box {
        background: linear-gradient(0deg, #000, #303030);
        border-radius: 15px;
        cursor: pointer;
        height: 250px;
        margin: 10px;
        position: relative;
        text-align: center;
        width: 160px;
    }

}