.contact-form {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    margin: 0px;
    border-radius: 15px;
    padding: 2px;
    z-index: 10;
}

form {
    background-color: black;
    padding: 25px;
    border-radius: 15px;
}

.form-glow::before,
.form-glow::after {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg,
            rgb(107, 0, 156),
            rgb(56, 56, 255),
            rgb(0, 140, 255),
            rgb(0, 166, 192),
            rgb(254, 1, 90),
            rgb(255, 2, 183),
            rgb(255, 143, 225));
    border-radius: 15px;
    background-size: 400%;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    z-index: -1;
    animation: animate 20s linear infinite;
}

@keyframes animate {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 300% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.form-glow::after {
    filter: blur(40px);
    opacity: 0.9;
}

/* form input */

.form-input {
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
}

/* .form-input {
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(39, 39, 39, 0.89);
    color: white;
} */

.form-input:focus {
    outline: none;
}

.form-phone-input {
    width: 100%;
    margin: 10px 0px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif !important;
}

/* phone number input */
.react-tel-input .form-control {
    position: relative;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 11px 11px 11px 48px !important;
    margin-left: 0;
    background: #FFFFFF;
    border: none !important;
    border-radius: 5px !important;
    line-height: 25px;
    height: auto !important;
    width: 100% !important;
    outline: none;
}

.react-tel-input .form-control:focus {
    outline: none !important;
}

.form-control {
    display: block;
    width: 100%;
    padding: 10px !important;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px !important;
    transition: none !important;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.react-tel-input .country-list {
    outline: none;
    z-index: 5 !important;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10pxrgba(0, 0, 0, 0.35);
    background-color: white;
    width: 200px !important;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    color: black !important;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: white !important;
    border: none !important;
    border-radius: none !important;
}

/* button effect */

/* .login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.login-box a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: rgb(255, 196, 0);
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px;
    z-index: 1;
    font-family: 'Open Sans', sans-serif !important;
}

.login-box a:hover {
    background: black;
    color: black;
    box-shadow: 0px 0px 20px 0px black;
    z-index: 2;
    background-color: rgb(255, 196, 0);
    font-weight: 700;
}

.login-box a span {
    position: absolute;
    display: block;
}

.login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgb(255, 196, 0));
    animation: btn-anim1 1s linear infinite;
    z-index: 2;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, rgb(255, 196, 0));
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s;
    z-index: 2;
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, rgb(255, 196, 0));
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s;
    z-index: 2;
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, rgb(255, 196, 0));
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s;
    z-index: 2;
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
} */

/* button effect */

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.container .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 40px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: black !important;
}

.container .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
}

.container .btn:hover a {
    letter-spacing: 2px;
}

.container .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
}

.container .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
}

.container .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: 0px;
    width: 77px;
    height: 10px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
}

.container .btn:hover::before

/*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
}


.container .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: 0px;
    width: 77px;
    height: 10px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
}

.container .btn:hover::after

/*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 10px;
}



.container .btn::before,
.container .btn::after {
    background: #2da0ff;
    box-shadow: 0 0 0px #2da0ff, 0 0 15px #2da0ff, 0 0 30px #2da0ff,
        0 0 60px #2da0ff;
}

/* toster theme */

.Toastify__toast-container--bottom-right {
    bottom: 6.5em !important;
    right: 2em;
}

/* captcha */
.captcha {
    position: relative;
}

.refresh-img {
    width: 13px;
}


#reload_href {
    color: rgb(255, 196, 0) !important;
    text-align: right;
    width: 100%;
    font-size: 13px;
    position: relative;
    padding-left: 20px;
}

#user_captcha_input:focus {
    outline: none !important;
}

#user_captcha_input {
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    line-height: inherit;
    padding: 10px;
    border: none;
    border-radius: 5px;
}

#canv {
    border-radius: 5px;
    width: 140px !important;
    height: 42px !important;
    margin-left: 10px;
    border: none !important;
}