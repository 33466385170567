@media (max-width: 1300px) {
  .logo-img {
    max-width: 300px;
    max-height: 100px;
    padding: 10px;
  }

  .nav-bar {
    height: 100px;
  }

  .menus {
    display: none;
  }

  .side-menu {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: black;
    padding: 0;
    z-index: 100;
    height: 100%;
  }

  .hamburger-menu {
    display: flex;
  }

  .hamburger-close-menu {
    display: flex;
  }

  .hamburger-menu img {
    width: 20px;
    height: auto;
  }

  .hamburger-menu span {
    width: 50px;
    height: 6px;
    margin: 3px;
    background: linear-gradient(40deg, rgb(68, 0, 255), rgb(255, 38, 96));
    animation-name: gradient;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }

  .side-menu a {
    text-decoration: none;
    padding: 6px;
    color: white;
    font-size: 40px;
    font-weight: 400;
    transition: all 0.5s;
    width: 100%;
    padding-left: 20px;
    border: 3px solid transparent;
  }

  .sub-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    background-color: rgba(255, 196, 0, 0.097);
  }

  .sub-menu a {
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    transition: color 0.3s ease, border-left 0.3s ease;
    color: rgb(195, 195, 195);
    width: 100%;
    font-size: 32px;
    padding-left: 40px;
  }

  /* Change color of dropdown links on hover */
  .sub-menu a:hover {
    background-color: transparent;
    color: rgb(255, 210, 64);
    border: 3px solid rgb(255, 196, 0);
  }

  /* • */
  .bullet {
    display: inline;
  }

  .dropdown-content a:hover .bullet {
    display: inline;
  }

  .down-arrow {
    width: 35px;
  }

  .hamburger-close-menu span {
    width: 50px;
    height: 6px;
    background: linear-gradient(40deg, rgb(68, 0, 255), rgb(255, 38, 96));
  }

  .right-arrow {
    width: 25px;
  }

}

@media (max-width: 960px) {
  .hamburger-close-menu span {
    width: 30px;
    height: 3px;
    background: linear-gradient(40deg, rgb(68, 0, 255), rgb(255, 38, 96));
  }

  .logo-img {
    max-width: 130px;
    max-height: 50px;
    padding: 5px;
  }

  .hamburger-menu {
    display: flex;
  }

  .hamburger-menu .hamburger {
    width: 30px;
    height: 3px;
    margin: 2px;
  }

  .hamburger-menu span {
    width: 30px;
    height: 3px;
    margin: 2px;
    background: linear-gradient(40deg, rgb(68, 0, 255), rgb(255, 38, 96));
    animation-name: gradient;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }

  .nav-bar {
    height: 60px;
  }

  .side-menu a {
    text-decoration: none;
    padding: 6px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    transition: all 0.5s;
    width: 100%;
    padding-left: 20px;
    border: 3px solid transparent;
  }

  .sub-menu a {
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    transition: color 0.3s ease, border-left 0.3s ease;
    color: rgb(195, 195, 195);
    width: 100%;
    font-size: 15px;
    padding-left: 40px;
  }

  .down-arrow {
    width: 15px;
  }


  .side-menu {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: black;
    padding: 0;
    z-index: 100;
    height: 100%;
  }

  .right-arrow {
    width: 15px;
  }

}